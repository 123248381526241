import { removeCountryCodeAndFormat } from './utils';

export const getShippingPayload = (data) => ({
  shippingfirstname: data?.firstName,
  shippinglastname: data?.lastName,
  shippingstreet: data?.address1,
  shippingstreet1: data?.address2,
  shippingcity: data?.city,
  shippingcountryid: data?.countryId,
  regionid: data?.state,
  postcode: data?.zip,
  telephone: removeCountryCodeAndFormat(data?.phone),
  id: data?.id,
  dpvconfirmationcode: data?.DPVConfirmation || '',
  customerverified: true,
});

export const getBillingPayload = (data) => ({
  billingfirstname: data?.firstName,
  billinglastname: data?.lastName,
  billingstreet: data?.address1,
  billingstreet1: data?.address2,
  billingcity: data?.city,
  billingcountryid: data?.countryId,
  regionid: data?.state,
  postcode: data?.zip,
  telephone: removeCountryCodeAndFormat(data?.phone),
  id: data?.id,
  dpvconfirmationcode: data?.DPVConfirmation || '',
  customerverified: true,
});
