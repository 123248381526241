import apiCall from '../../../commons/helpers/apiCall';
import { getStateDetailsFromStatesList } from '../../../utils/Utils';
import axios from 'axios';

export async function fetchAdBlockerStatus() {
  const result = await fetch(`https://www.google-analytics.com/j/collect?v=1`)
    .then((res) => {
      return !(res.status === 200 || res.status === 204);
    })
    .catch((err) => {
      return true;
    });
  return result;
}

export async function fetchCountryStatesList(country) {
  const result = await apiCall({
    endpoint: `country-states/${country}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
    authorize: false,
  });
  return result;
}

export async function fetchUSPSAccessToken() {
  const result = await apiCall({
    endpoint: `usps/oauth-token`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

// Verify Address using USPS API
export async function verifyAddress(address, appState) {
  const response = await axios.get('/api/usps-validation', {
    params: {
      streetAddress: address.address1,
      secondaryAddress: address.address2 || '',
      city: address.city,
      state: getStateDetailsFromStatesList(address.state, appState['US'])
        ?.state_code,
      ZIPCode: address.zip,
    },
  });
  return response.data;
}
