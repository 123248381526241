import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import router from 'next/router';
import store, { AppState } from '../../app/store';
import {
  setSurveyItem,
  checkSurveyState,
  getSurveyOptions,
} from '../survey/surveyState';
import {
  customerInfo,
  getActiveSubscriptionsWithLimit,
} from '../subscription/subscriptionState';
import { SUCCESSMESSAGE_PRODUCT } from '../../../components/body/orderSuccessPage/OrderSuccessPage';
import { getProducts } from '../../../commons/utils/productsHelper';
import Router from 'next/router';
import { toast } from 'react-toastify';

const products = getProducts();
import {
  applyCoupon,
  createOrder,
  createQuote,
  deleteCoupon,
  deleteQuoteItemById,
  fetchQuoteById,
  fetchTax,
  updateQuote,
  createNewOrder,
  prepCart,
  fetchOrderDetails,
} from './cartApi';
import { FBActionOrders } from '../../../commons/helpers/Analytics/fbPixel';
import { GAPurchaseOrder } from '../../../commons/helpers/Analytics/googleAnalytics';
import { ConversionEvents } from '../../../commons/helpers/Analytics/rakutenShoppersAPI';
import {
  getFormattedAddress,
  removeCountryCodeAndFormat,
  removeCountryCodeByValueAttentive,
} from '../../../commons/utils/utils';
import { cacluclateSubTotal, getBeaconToken } from '../../../commons/utils';
import { cookiesValueSplit } from '../../../commons/utils/utils';
import { pinterestPurchaseOrder } from '../../../commons/helpers/Analytics/pinterest';
import { getCustomerInfo, updateAbandonCartData } from '../user/userState';
import { statesWithId } from '../../../components/body/support/forms/shared/FormsData';
import { cryptoJSAesDecrypt } from '../../../commons/helpers/apiCall/chipers';
import { UN_AUTHORIZED } from '../../../commons/Constants';
import { encryptionForPaymentInfo } from '../../../commons/utils/encryption-forge';
import { getToken } from '../../../commons/utils/extractCustomerToken';
import {
  getBillingPayload,
  getShippingPayload,
} from '../../../commons/utils/payloadFormatter';
import { productsKeyConstants, ProductsCategory } from '../../../config';
import { vwoHelper } from '../../../commons/helpers/Analytics/vwo/vwoHelper';
import cookie from 'cookie';
import { config } from '../../../config';

export const CartItem = {
  key: '',
  category: '',
  isSubscription: null,
  quantity: null,
  isRoot: null,
};

export const FreeProduct = {
  sku: '',
  parentProductId: '',
  triggerType: '',
  quantity: '',
  productId: '',
  name: '',
};

export const CartState = {
  items: {},
  quoteId: {},
  freeProduct: [],
  quote: {},
  placedOrder: {},
  updatingQuote: null,
  referralCouponCode: '',
  cartPrepared: null,
  prepareCartErrorMessage: '',
  itemsCount: Number,
  subTotal: Number,
  inSyncWithQuote: false,
};

const initialState = {
  items: {},
  quoteId: null,
  freeProduct: [],
  quote: null,
  placedOrder: null,
  updatingQuote: false,
  referralCouponCode: '',
  cartPrepared: null,
  prepareCartErrorMessage: '',
  isCartCleared: false,
  itemsCount: 0,
  subTotal: 0,
  inSyncWithQuote: false,
};

const getFormattedProductDetails = (reduxCart) => {
  let productDetails = [];
  for (let [id, value] of Object.entries(reduxCart)) {
    let key = value.key;
    let product = products[key];
    let item = {
      ...product.quoteParams,
      quantity: value.quantity,
      isSubscription: value.isSubscription,
      productId: id,
      itemId: value.id,
    };
    productDetails.push(item);
  }
  return productDetails;
};

const getNewFormattedOrder = (
  cart,
  products,
  customer,
  shipping,
  billing,
  isShippingSameAsBilling,
  payment,
  fbEventItems,
  orderFor,
  customerInfo
) => {
  const {
    quoteId,
    items,
    freeProduct,
    quote,
    referralCouponCode,
    cartPrepared,
  } = cart;
  let applicableRefCode = '';
  let cookiesValue = cookiesValueSplit();

  let { email } = orderFor;
  let { telephone } = shipping;
  let orderQuery = {};
  let offers = [];
  let cookiesFinalValue = {};
  let paymentInfo = {};
  if (payment?.payment_method == 'vd_rfpayment') {
    paymentInfo = {
      ...payment,
    };
    // ! Remove this once we switch to a better encryption method on magento. We are not able to add this much data for encryption on this method
    delete paymentInfo?.details;
  } else {
    paymentInfo = {
      vd_elavon_cc_name: payment.cardType,
      vd_elavon_cc_number: payment.cardNumber,
      vd_elavon_expiration: payment.expirationMonth,
      vd_elavon_expiration_yr: payment.expirationYear,
      vd_elavon_cc_cid: payment.cvv,
    };
  }
  paymentInfo = encryptionForPaymentInfo(paymentInfo);
  for (let item in items) {
    let cartItem = items[item];
    let product = products[cartItem.key];
    // Only apply referral code to RF products.
    if (
      // cartItem.category == 'REL' &&
      !applicableRefCode &&
      referralCouponCode
    ) {
      applicableRefCode = referralCouponCode;
    }
    offers.push({
      productId: product?.quoteParams.productId,
      planId: product?.quoteParams.planId,
      offerId: product?.quoteParams.offerId,
      quantity: cartItem.quantity,
      isSubscription: cartItem.isSubscription,
    });
  }
  orderQuery['payment'] = paymentInfo;
  if (customer?.id && customerInfo.id) {
    orderQuery['customerId'] = customer.id;
  }

  if (cookiesValue['iterableEndUserId']) {
    cookiesFinalValue['iterableEndUserId'] = cookiesValue.iterableEndUserId;
  }
  if (cookiesValue['iterableTemplateId']) {
    cookiesFinalValue['iterableTemplateId'] = cookiesValue.iterableTemplateId;
  }
  if (cookiesValue['iterableEmailCampaignId']) {
    cookiesFinalValue['iterableEmailCampaignId'] =
      cookiesValue.iterableEmailCampaignId;
  }
  if (cookiesValue['iterableMessageId']) {
    cookiesFinalValue['iterableMessageId'] = cookiesValue.iterableMessageId;
  }
  let gaClientID = '';
  if (typeof window.gaGlobal !== 'undefined') {
    gaClientID = window.gaGlobal.vid;
  }

  return {
    quoteDetails: {
      ...orderQuery,
      quoteId: quoteId || '',
      couponCode: quote?.coupon_code || '',
      freeProduct: freeProduct,
      referralCouponCode: applicableRefCode,
      offers,
      sslAvsResponse: cartPrepared,
      beaconCode: getBeaconToken(),
      ga_client_Id: gaClientID,
      email,
    },
    fbPixelEvent: fbEventItems,
    ...cookiesFinalValue,
    // iterableEndUserId: cookiesValue.iterableEndUserId,
    // iterableTemplateId: cookiesValue.iterableTemplateId,
    // iterableEmailCampaignId: cookiesValue.iterableEmailCampaignId,
    // iterableMessageId: cookiesValue.iterableMessageId,
    attentive: {
      email,
      telephone: removeCountryCodeByValueAttentive(telephone),
    },
  };
};

const getFormattedCart = (cart, products, items, customerId) => {
  const { quoteId } = cart;
  let cartQuery = {};
  let extraItems = [];
  for (let item in items) {
    let cartItem = items[item];
    if (cartItem.isRoot) {
      cartQuery = {
        ...products[cartItem.key]?.quoteParams,
        customerId,
        quantity: cartItem.quantity,
        isSubscription: cartItem.isSubscription,
        quoteId,
        itemAction: true,
      };
    } else {
      extraItems.push({
        productId: item,
        planId: products[cartItem.key]?.quoteParams.planId,
        offerId: products[cartItem.key]?.quoteParams.offerId,
        quantity: cartItem.quantity,
        isSubscription: cartItem.isSubscription,
      });
    }
  }
  cartQuery['existingItemsOffer'] = extraItems;
  cartQuery['beaconCode'] = getBeaconToken();
  cartQuery['freeProducts'] = cart.freeProduct || [];
  return {
    // cartItem: cartQuery, Recent changes to cartItem to quoteDetails in update quotes only
    quoteDetails: cartQuery,
  };
};

const getFormattedOrder = (
  cart,
  products,
  customer,
  shipping,
  billing,
  isShippingSameAsBilling,
  payment,
  orderFor,
  customerInfo
) => {
  const { trigger_sms } = orderFor;
  const { quoteId, items, freeProduct, quote, referralCouponCode } = cart;
  let applicableRefCode = '';
  let orderQuery = {};
  let offers = [];
  let attentive = {};
  let paymentInfo = {};
  if (payment?.payment_method == 'vd_rfpayment') {
    paymentInfo = {
      ...payment,
    };
    // ! Remove this once we switch to a better encryption method on magento. We are not able to add this much data for encryption on this method
    delete paymentInfo?.details;
  } else {
    paymentInfo = {
      vd_elavon_cc_name: payment.cardType,
      vd_elavon_cc_number: payment.cardNumber,
      vd_elavon_expiration: payment.expirationMonth,
      vd_elavon_expiration_yr: payment.expirationYear,
      vd_elavon_cc_cid: payment.cvv,
    };
  }
  paymentInfo = encryptionForPaymentInfo(paymentInfo);
  for (let item in items) {
    let cartItem = items[item];
    let product = products[cartItem.key];
    if (!applicableRefCode && referralCouponCode) {
      applicableRefCode = referralCouponCode;
    }
    offers.push({
      productId: product?.quoteParams.productId,
      planId: product?.quoteParams.planId,
      offerId: product?.quoteParams.offerId,
      quantity: cartItem.quantity,
      isSubscription: cartItem.isSubscription,
    });
  }
  attentive['email'] = customer.email;
  attentive['telephone'] = removeCountryCodeByValueAttentive(
    shipping.telephone,
    'telephone'
  );
  if (shipping?.id) {
    if (shipping?.id == 'new') {
      orderQuery['shipping'] = {
        ...shipping,
        telephone: removeCountryCodeAndFormat(customer.phone),
        email: customer.email,
      };
    } else {
      orderQuery['existingShippingId'] = shipping.id;
      orderQuery['shippingUspsFlag'] = {
        existingShippingId: shipping.id,
        dpvconfirmationcode: shipping.dpvconfirmationcode,
        customerverified: true,
      };
    }
  } else {
    orderQuery['shipping'] = {
      ...shipping,
      telephone: removeCountryCodeAndFormat(shipping.telephone),
      email: customer.email,
    };
  }
  if (billing?.id) {
    if (billing?.id == 'new') {
      orderQuery['billing'] = {
        ...billing,
        telephone: removeCountryCodeAndFormat(customer.phone),
      };
    } else {
      orderQuery['existingBillingId'] = billing.id;
      orderQuery['billingUspsFlag'] = {
        existingBillingId: billing.id,
        dpvconfirmationcode: billing.dpvconfirmationcode,
        customerverified: true,
      };
    }
  } else {
    orderQuery['billing'] = {
      ...billing,
      telephone: removeCountryCodeAndFormat(billing.telephone),
    };
  }
  orderQuery['billingsameasship'] = isShippingSameAsBilling;
  orderQuery['payment'] = paymentInfo;
  if (customer?.id && customerInfo?.id) {
    orderQuery['customerId'] = customer.id;
  }
  return {
    quoteDetails: {
      ...orderQuery,
      quoteId: quoteId || '',
      couponCode: quote?.coupon_code || '',
      referralCouponCode: applicableRefCode,
      freeProduct: freeProduct,
      offers,
      trigger_sms,
      beaconCode: getBeaconToken(),
      customer: {
        email: customer.email,
        firstname: customer.firstName,
        lastname: customer.lastName,
        telephone: removeCountryCodeAndFormat(customer.phone),
      },
    },
    attentive,
  };
};

export const prepareCart = createAsyncThunk(
  'PREPARE_CART',
  async ({ customer, payment }, { dispatch, getState, rejectWithValue }) => {
    const {
      cart,
      user: {
        shipping,
        billing,
        isBillingSameAsShipping,
        customerInfo,
        orderFor,
      },
    } = getState();
    let formattedOrder = getFormattedOrder(
      cart,
      products,
      customer,
      getShippingPayload(shipping),
      getBillingPayload(billing),
      isBillingSameAsShipping,
      payment,
      orderFor,
      customerInfo
    );
    const res = await prepCart(formattedOrder);
    // For coupon code flow
    if (!res[0]?.isCouponValid) {
      await dispatch(getQuote(cart?.quoteId));
    }
    return res[0];
  }
);
export const placeNewOrder = createAsyncThunk(
  'PLACE_NEWORDER',
  async ({ customer, payment }, { dispatch, getState, rejectWithValue }) => {
    const {
      cart,
      user: {
        shipping,
        billing,
        isBillingSameAsShipping,
        customerInfo,
        orderFor,
      },
    } = getState();

    const fbEventItems = FBActionOrders(products, cart, orderFor);
    let formattedOrder = getNewFormattedOrder(
      cart,
      products,
      customer,
      shipping,
      billing,
      isBillingSameAsShipping,
      payment,
      fbEventItems,
      orderFor,
      customerInfo
    );
    if (
      Object.keys(cart?.items).includes(
        products[productsKeyConstants.RF_RELIEF_FACTOR_QS]?.productId ||
          products[productsKeyConstants.RF_RELIEF_FACTOR_60_CT_SINGLE]
            ?.productId
      )
    ) {
      dispatch(setSurveyItem());
    }
    try {
      const res = await createNewOrder(formattedOrder);
      if (res[0]?.incrementId) {
        let result = SUCCESSMESSAGE_PRODUCT?.filter((each) => {
          return cart?.quote?.items?.find((item) => {
            if (cart.items[item?.product_id]?.key === each?.id) {
              return each;
            }
          });
        });
        if (
          result?.some((res) =>
            [
              productsKeyConstants.RF_RELIEF_FACTOR_QS,
              productsKeyConstants.RF_RELIEF_FACTOR_60_CT_SINGLE,
            ].includes(res?.id)
          )
        ) {
          dispatch(getSurveyOptions(ProductsCategory.RELIEF_FACTOR));
        }
        dispatch(checkSurveyState(result));
        const entityId = res[0].entityId;
        const response = await fetchOrderDetails(entityId);
        if (response[0]?.entity_id) {
          ConversionEvents(response[0]);
        }
      }
      const orderId = res[0].incrementId;

      var isCustomer = 0;

      if (typeof customerInfo.id !== 'undefined') {
        isCustomer = 1;
      }

      GAPurchaseOrder(products, cart, orderId, orderFor, isCustomer);
      pinterestPurchaseOrder(products, cart, orderId);
      // const userId = cookie.parse(document.cookie).vwo_uid;
      // Object.values(cart.items).forEach((item) => {
      //   if (item.key.includes('REL')) {
      //     const selectedProduct = products[item.key];
      //     const options = {
      //       eventProperties: {
      //         'order-id': orderId,
      //         'product-id': selectedProduct.productId,
      //         'product-name': selectedProduct.productName,
      //         'sku': selectedProduct.sku,
      //         'price': selectedProduct.recurringPrice,
      //         'quantity': item.quantity,
      //       },
      //     };
      //     vwoHelper.vwoClientInstance.track(
      //       config.vwo.campaignKey,
      //       userId,
      //       'order-placed',
      //       options
      //     );
      //   }
      // });
      router.push(`/ordersuccess?orderid=${orderId}`);
      // # Move subscription logic from create order api
      if (customerInfo.id) {
        dispatch(
          getActiveSubscriptionsWithLimit({
            customerId: customerInfo.id,
            limit: 2,
          })
        );
        dispatch(getCustomerInfo(getToken()));
      }
      // returns order id
      return res[0];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const getQuote = createAsyncThunk('GET_QUOTE', async (id) => {
//   const res = await fetchQuoteById(id);
//   console.log(res);
//   if (res && Array.isArray(res)) {
//     let quote = res[0];
//     let quoteItems = quote?.items;
//     if (!quoteItems) {
//       return null;
//     }
//     let count = quote?.items_count || 0;
//     // let productkeys = Object.keys(products);
//     let cartItems = {};
//     quoteItems.map((item, index) => {
//       let product = Object.values(products).find(
//         (value) =>
//           value.productId === item.product_id &&
//           value.isSubscription === item.is_subscription
//       );
//       let productData = {
//         key: product.key,
//         quantity: item.qty,
//         isSubscription: item.is_subscription,
//         category: product.category,
//         isRoot: index === quoteItems.length - 1,
//         productId: product.productId,
//       };
//       cartItems[product.productId] = productData;
//     });
//     return {
//       quoteId: id,
//       items: cartItems,
//       quote,
//       itemsCount: count,
//     };
//   } else {
//     return null;
//   }
// });

export const getQuote = createAsyncThunk('GET_QUOTE', async (id) => {
  const res = await fetchQuoteById(id);
  if (res && Array.isArray(res)) {
    let quote = res[0];
    let quoteItems = quote?.items;
    if (!quoteItems) {
      return null;
    }
    let count = quote?.items_count || 0;
    // let productkeys = Object.keys(products);
    let cartItems = {};
    quoteItems.map((item, index) => {
      let product = Object.values(products).find(
        (value) =>
          value.productId === item.product_id &&
          value.isSubscription === item.is_subscription
      );
      let productData = {
        key: product.key,
        quantity: item.qty,
        isSubscription: item.is_subscription,
        category: product.category,
        isRoot: index === quoteItems.length - 1,
        productId: product.productId,
        id: item.item_id,
      };
      cartItems[product.productId] = productData;
    });

    let quoteFreeGifts = quote?.freeProduct;
    let tempData = [];
    quoteFreeGifts?.forEach((item) => {
      let tempItem = {};
      tempItem['itemId'] = item?.item_id;
      tempItem['sku'] = item?.sku;
      tempItem['name'] = item?.name;
      tempItem['parentProductId'] = item?.parent_product_id;
      tempItem['productId'] = item?.product_id;
      tempItem['triggerType'] = item?.trigger_type;
      tempItem['quantity'] = item?.qty;
      tempData.push(tempItem);
    });
    return {
      items: cartItems,
      quote,
      freeProduct: tempData,
      itemsCount: count,
    };
  } else {
    return null;
  }
});

export const getAbandonedData = createAsyncThunk(
  'GET_ABANDONED_QUOTE',
  async (encrypted_id, { dispatch, getState }) => {
    const { orderFor } = getState().user;
    let cartquoteId = getState().cart.quoteId;
    let id = cryptoJSAesDecrypt(encrypted_id) || cartquoteId;
    const res = await fetchQuoteById(id);
    if (res && Array.isArray(res)) {
      let quote = res[0];
      let quoteItems = quote?.items;
      if (!quoteItems) {
        return null;
      }
      const cust_info = quote?.customer_info;
      const { addresses } = getState().user.customerInfo;
      let productkeys = Object.keys(products);

      const shipping_data = cust_info.shipping_address;
      const billing_data = cust_info.billing_address;

      const newOrderFor = {
        id: cust_info.customer_id || orderFor.id,
        firstName: cust_info.customer_firstname || orderFor.firstName,
        lastName: cust_info.customer_lastname || orderFor.lastName,
        phone:
          cust_info.customer_telephone ||
          shipping_data?.shipping_telephone ||
          orderFor.phone,
        email: cust_info.customer_email || orderFor.email,
        trigger_sms: false,
        supportTicket: false,
      };
      let cartItems = {};
      quoteItems.map((item, index) => {
        let product = Object.values(products).find(
          (value) =>
            value.productId === item.product_id &&
            value.isSubscription === item.is_subscription
        );
        let productData = {
          key: product?.key,
          quantity: item.qty,
          isSubscription: item.is_subscription,
          category: product?.category,
          isRoot: index === quoteItems.length - 1,
          productId: product?.productId,
          id: item.item_id,
        };
        cartItems[product.productId] = productData;
      });
      let quoteFreeGifts = quote?.freeProduct;
      let tempData = [];
      quoteFreeGifts?.forEach((item) => {
        let tempItem = {};
        tempItem['itemId'] = item?.item_id;
        tempItem['sku'] = item?.sku;
        tempItem['name'] = item?.name;
        tempItem['parentProductId'] = item?.parent_product_id;
        tempItem['productId'] = item?.product_id;
        tempItem['triggerType'] = item?.trigger_type;
        tempItem['quantity'] = item?.qty;
        tempData.push(tempItem);
      });
      let shipping = getFormattedAddress(shipping_data, 'shipping');
      let billing = getFormattedAddress(billing_data, 'billing');
      if (shipping) {
        const hasSameShippingId = addresses?.some((address) => {
          return address.id == shipping_data?.id;
        });
        const hasSameBillingId = addresses?.some((address) => {
          return address.id == billing_data?.id;
        });
        shipping.id = hasSameShippingId ? shipping_data.id : '';
        if (billing) {
          billing.id = hasSameBillingId ? billing_data.id : '';
        }
        if (shipping_data?.same_as_billing) {
          billing = getFormattedAddress(shipping_data, 'billing');
          billing.id = hasSameBillingId ? shipping_data.id : '';
        }
      }
      let _checkoutStep = shipping?.shippingfirstname
        ? 'shipping'
        : 'information';
      let _stageIsUnlocked =
        _checkoutStep === 'information'
          ? { information: true, shipping: false, payment: false }
          : { information: true, shipping: true, payment: false };
      const _userState = {
        orderFor: newOrderFor,
        customerInfo: {},
        shipping: shipping,
        billing: billing,
        stageIsUnlocked: _stageIsUnlocked,
        isBillingSameAsShipping:
          shipping_data?.same_as_billing === false ? false : true,
        checkoutStep: _checkoutStep,
        highestCheckoutStep: _checkoutStep,
      };
      dispatch(updateAbandonCartData(_userState));
      return {
        quoteId: id,
        items: cartItems,
        freeProduct: tempData,
        quote: res[0],
      };
    } else {
      return null;
    }
  }
);

export const setQuoteId = createAsyncThunk('SET_QUOTE_ID', async (id) => {
  if (id) {
    return id;
  } else {
    return null;
  }
});

export const addItemToCart = createAsyncThunk(
  'ADD_ITEM_TO_CART',
  async (
    { productId = null, data = null, quoteItems = null },
    { getState, dispatch }
  ) => {
    const { cart } = getState();
    const { quoteId, items } = cart;
    // if (!quoteId) {
    //   return {
    //     items: { [productId]: { ...data, id: '', isRoot: true } },
    //     placedOrder: null,
    //   };
    // }
    if (quoteItems) {
      return {
        items: quoteItems,
        placedOrder: null,
      };
    } else {
      if (
        products[data.key].maxQty >= data.quantity ||
        Object.keys(items).length === 0
      ) {
        let newItems = { ...items };
        let itemId = '';
        for (let item in items) {
          if (newItems[item].category === data.category) {
            itemId = newItems[item].id;
            delete newItems[item];
            continue;
          }
          if (newItems[item].isRoot) {
            newItems[item] = { ...newItems[item], isRoot: false };
          }
        }
        newItems[productId] = { ...data, id: itemId, isRoot: true, productId };
        return {
          items: newItems,
          placedOrder: null,
        };
      } else {
        dispatch(quantityWarning({ id: productId, status: true }));
        setTimeout(() => {
          dispatch(quantityWarning({ id: productId, status: false }));
        }, 5000);
        return {
          items: items,
          placedOrder: null,
        };
      }
    }
  }
);

export const addItemToQuote = createAsyncThunk(
  'ADD_ITEM_TO_QUOTE',
  async (data, { getState, dispatch }) => {
    const { cart, user } = getState();
    const { customerInfo } = user;
    const { quoteId, items, freeProduct, referralCouponCode } = cart;
    let _customerId = customerInfo.id || '';
    let applicableRefCode = '';
    if (!applicableRefCode && referralCouponCode) {
      applicableRefCode = referralCouponCode;
    }
    let cartItem = {
      freeProducts: freeProduct,
      customerId: _customerId,
      beaconCode: getBeaconToken(),
      itemAction: true,
      quoteId,
      referralCouponCode: applicableRefCode,
      productDetails: getFormattedProductDetails(items),
    };
    if (!quoteId) {
      const res = await createQuote({ cartItem }, 'V3');
      await dispatch(getQuote(res[0].quoteId));
      return {
        quoteId: res[0].quoteId,
        placedOrder: null,
      };
    }
    await updateQuote(
      {
        quoteDetails: cartItem,
      },
      'V3'
    );
    await dispatch(getQuote(quoteId));
    return {
      placedOrder: null,
    };
  }
);

// export const addItemToQuote = createAsyncThunk(
//   'ADD_ITEM_TO_QUOTE',
//   async ({ productId }, { getState, dispatch }) => {
//     const { cart, user } = getState();
//     const { customerInfo } = user;
//     const { quoteId, items } = cart;
//     let _customerId = customerInfo.id || '';
//     if (!quoteId) {
//       const res = await createQuote({
//         cartItem: {
//           ...products[data.key]?.quoteParams,
//           customerId: _customerId,
//           productId,
//           quantity: data.quantity,
//           isSubscription: data.isSubscription,
//           beaconCode: getBeaconToken(),
//           itemAction: true,
//         },
//       });
//       await dispatch(getQuote(res[0].quoteId));
//       return {
//         items: { [productId]: { ...data, isRoot: true } },
//         quoteId: res[0].quoteId,
//         placedOrder: null,
//       };
//     }
//     let newItems = { ...items };
//     for (let item in items) {
//       if (newItems[item].category === data.category) {
//         delete newItems[item];
//         continue;
//       }
//       if (newItems[item].isRoot) {
//         newItems[item] = { ...newItems[item], isRoot: false };
//       }
//     }
//     newItems[productId] = { ...data, isRoot: true };
//     let formattedCart = getFormattedCart(cart, products, newItems, _customerId);
//     await updateQuote(formattedCart);
//     await dispatch(getQuote(quoteId));
//     return {
//       items: newItems,
//       quoteId,
//       placedOrder: null,
//     };
//   }
// );

export const updateProduct = createAsyncThunk(
  'UPDATE_PRODUCT',
  async ({ itemId, qty = '', offerId, type }, { getState, dispatch }) => {
    const { cart, user } = getState();
    const { customerInfo } = user;
    const { quoteId, quote } = cart;
    const { items } = quote;

    const formattedProduct = [];
    items.forEach((item) => {
      const obj = {};
      if (type == 'qtyupdate') {
        obj['planId'] = item.plan_id;
        obj['offerId'] = item.offer_id;
        obj['quantity'] = item.item_id == itemId ? qty : item.qty;
        obj['productId'] = item.product_id;
        obj['isSubscription'] = item.is_subscription;
        obj['itemId'] = item.item_id;
        obj['action'] = item.item_id == itemId ? 'qtyupdate' : '';
      } else {
        obj['planId'] = item.plan_id;
        obj['offerId'] = offerId;
        obj['quantity'] = item.qty;
        obj['productId'] = item.product_id;
        obj['isSubscription'] =
          item.item_id == itemId ? true : item.is_subscription;
        obj['itemId'] = item.item_id;
        obj['action'] = item.item_id == itemId ? type : '';
      }
      formattedProduct.push(obj);
    });
    let _customerId = customerInfo.id || '';
    const payload = {
      quoteDetails: {
        itemAction: true,
        quoteId: quoteId,
        customerId: _customerId,
        couponCode: '',
        beaconCode: '',
        productDetails: formattedProduct,
      },
    };
    await updateQuote(payload, 'V3');
    await dispatch(getQuote(quoteId));
  }
);

// export const removeItemFromCart = createAsyncThunk(
//   'REMOVE_ITEM_FROM_CART',
//   async ({ quoteId, itemId, productId }, { getState }) => {
//     const { cart } = getState();
//     const { items } = cart;
//     const res = await deleteQuoteItemById(quoteId, itemId);
//     if (cart?.itemsCount > 1) {
//       if (res && Array.isArray(res)) {
//         let newItems = { ...items };
//         if (items[productId]?.isRoot) {
//           let nextProduct = Object.keys(items).find(
//             (prodId) => prodId !== productId
//           );
//           newItems[nextProduct] = { ...newItems[nextProduct], isRoot: true };
//         }
//         delete newItems[productId];
//         return {
//           items: newItems,
//           quote: res[0],
//         };
//       }
//     } else {
//       return {
//         quote: null,
//         itemsCount: 0,
//       };
//     }
//   }
// );

export const removeItemFromCart = createAsyncThunk(
  'REMOVE_ITEM_FROM_CART',
  async ({ itemId, productId }, { getState }) => {
    const { cart } = getState();
    const { items, quoteId, itemsCount } = cart;
    let newItems = { ...items };
    if (quoteId && itemId) {
      const res = await deleteQuoteItemById(quoteId, itemId);
      if (res && Array.isArray(res)) {
        delete newItems[productId];
        return {
          items: newItems,
          quote: res[0],
        };
      }
    } else {
      delete newItems[productId];
      return {
        items: newItems,
      };
    }
    // if (cart?.itemsCount > 1) {
    //   if (res && Array.isArray(res)) {
    //     let newItems = { ...items };
    //     if (items[productId]?.isRoot) {
    //       let nextProduct = Object.keys(items).find(
    //         (prodId) => prodId !== productId
    //       );
    //       newItems[nextProduct] = { ...newItems[nextProduct], isRoot: true };
    //     }
    //     delete newItems[productId];
    //     return {
    //       items: newItems,
    //       quote: res[0],
    //     };
    //   }
    // }
    // else {
    //   return {
    //     quote: null,
    //     itemsCount: 0,
    //   };
    // }
  }
);

export const updateTax = createAsyncThunk(
  'UPDATE_TAX',
  async (data, { getState, dispatch }) => {
    const { cart, user } = getState();
    const { customerInfo, shipping } = user;
    const { quoteId } = cart;
    // let shippingAdd = null;
    // if (data) {
    //   shippingAddress = data;
    // }
    // const updatedData = shippingAdd?.customerId
    //   ? shippingAdd
    //   : {
    //       ...shippingAdd,
    //       shipping: {
    //         ...shippingAdd.shipping,
    //         telephone: removeCountryCodeAndFormat(data?.shipping?.telephone),
    //       },
    //     };

    let updateQuery = {
      quoteId,
      shipping: {
        shippingfirstname: shipping?.firstName,
        shippinglastname: shipping?.lastName,
        shippingstreet: shipping?.address1,
        shippingstreet1: shipping?.address2 || '',
        shippingcity: shipping?.city,
        shippingcountryid: 'US',
        regionid: shipping?.state,
        postcode: shipping?.zip,
        telephone: removeCountryCodeAndFormat(shipping?.phone),
      },
    };
    if (customerInfo?.id) {
      updateQuery[`customerId`] = customerInfo.id;
      if (shipping?.id) {
        delete updateQuery.shipping;
        updateQuery['shippingAddressId'] = shipping?.id;
      }
    }
    const res = await fetchTax(updateQuery);
    if (res && Array.isArray(res)) {
      return res[0];
    } else {
      return null;
    }
  }
);

export const addCoupon = createAsyncThunk(
  'APPLY_COUPON',
  async ({ couponCode, quoteId }) => {
    const res = await applyCoupon({ couponCode, quoteId });
    if (res && Array.isArray(res)) {
      return res[0];
    } else {
      return {};
    }
  }
);

export const removeCoupon = createAsyncThunk(
  'REMOVE_COUPON',
  async (quoteId) => {
    const res = await deleteCoupon(quoteId);
    if (res && Array.isArray(res)) {
      return res[0];
    } else {
      return {};
    }
  }
);

export const placeOrder = createAsyncThunk(
  'PLACE_ORDER',
  async ({ customer, payment }, { dispatch, getState, rejectWithValue }) => {
    const {
      cart,
      user: { shipping, billing, isBillingSameAsShipping, customerInfo },
    } = getState();
    dispatch(setSurveyItem());
    let formattedOrder = getFormattedOrder(
      cart,
      products,
      customer,
      shipping,
      billing,
      isBillingSameAsShipping,
      payment
    );
    const res = await createOrder(formattedOrder);
    if (res[0]?.incrementId) {
      let result = SUCCESSMESSAGE_PRODUCT?.filter((each) => {
        return cart?.quote?.items?.find((item) => {
          if (cart.items[item?.product_id]?.key === each?.id) {
            return each;
          }
        });
      });
      dispatch(checkSurveyState(result));
    }
    // const res = [{
    //   incrementId: '101',
    //   entityId: '400'
    // }]
    router.push(`/ordersuccess?orderid=${res[0].incrementId}`);
    // # Move subscription logic from create order api
    if (customerInfo.id) {
      dispatch(
        getActiveSubscriptionsWithLimit({
          customerId: customerInfo.id,
          limit: 2,
        })
      );
    }
    // returns order id
    return res[0];
  }
);

export const quantityWarning = createAsyncThunk(
  'QUANTITY_WARNING',
  async (data) => {
    return data;
  }
);

export const addFreeGift = createAsyncThunk(
  'ADD_FREE_GIFT',
  async (data, { dispatch, getState, rejectWithValue }) => {
    const { cart } = getState();
    let freeProducts = cart.freeProduct;
    let tempData = [...freeProducts];
    if (tempData.filter((fg) => fg.sku == data.sku).length == 0) {
      tempData.push(data);
    }
    let itemIndex = tempData.findIndex((fg) => fg.sku == data.sku);
    if (itemIndex != -1) {
      tempData[itemIndex] = data;
    }
    return tempData;
  }
);

export const removeFreeGift = createAsyncThunk(
  'REMOVE_FREE_GIFT',
  async (data, { dispatch, getState, rejectWithValue }) => {
    const { cart } = getState();
    let freeProducts = cart.freeProduct;
    let tempData = [...freeProducts];
    let itemIndex = tempData.findIndex((fg) => fg.sku == data.sku);
    tempData.splice(itemIndex, 1);
    return tempData;
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateFreeGift: (state, action) => {
      return {
        ...state,
        freeProduct: {
          ...state.freeProduct,
          ...action.payload,
        },
      };
    },
    setReferralCode: (state, action) => {
      state.referralCouponCode = action.payload;
    },
    resetQuote: (state, action) => {
      return {
        ...state,
        quote: null,
      };
    },
    removeOldOrder: (state, action) => {
      return {
        ...state,
        placedOrder: null,
      };
    },
    setClearCart: (state, action) => {
      return {
        ...state,
        isCartCleared: false,
      };
    },
    resetCart: (state, action) => {
      let clearState = { ...initialState, isCartCleared: true };
      return {
        ...state,
        ...clearState,
      };
    },
    resetFreeGift: (state, action) => {
      return {
        ...state,
        freeProduct: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setQuoteId.pending, (state, action) => {
        return {
          ...state,
          quoteId: action.payload,
        };
      })
      .addCase(setQuoteId.fulfilled, (state, action) => {
        if (action?.payload) getQuote(action.payload);
        else {
          state.quote = null;
        }
      })
      .addCase(addItemToCart.pending, (state, action) => {
        state.updatingQuote = true;
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        let items = Object.values(action.payload?.items || {});
        let subTotal = cacluclateSubTotal(items);
        return {
          ...state,
          ...action.payload,
          itemsCount: items.length,
          subTotal,
          updatingQuote: false,
          inSyncWithQuote: false,
        };
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          `Quote for the requested quoteId doesn't exist. Please try again with valid quoteId`;
        if (resetCart) {
          return initialState;
        } else {
          state.cartPrepared = 'failed';
          state.prepareCartErrorMessage = action?.error?.message;
        }
      })
      .addCase(addItemToQuote.pending, (state, action) => {
        state.updatingQuote = true;
      })
      .addCase(addItemToQuote.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          updatingQuote: false,
          inSyncWithQuote: true,
        };
      })
      .addCase(addItemToQuote.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          `Quote for the requested quoteId doesn't exist. Please try again with valid quoteId`;
        if (resetCart) {
          return initialState;
        } else {
          state.cartPrepared = 'failed';
          state.prepareCartErrorMessage = action?.error?.message;
        }
      })
      .addCase(updateProduct.pending, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(updateProduct.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          `Quote for the requested quoteId doesn't exist. Please try again with valid quoteId`;
        if (resetCart) {
          return initialState;
        } else {
          state.cartPrepared = 'failed';
          state.prepareCartErrorMessage = action?.error?.message;
        }
      })
      .addCase(getAbandonedData.fulfilled, (state, action) => {
        let items = Object.values(action.payload?.items || {});
        let subTotal = cacluclateSubTotal(items);
        return {
          ...state,
          ...action.payload,
          itemsCount: items.length,
          subTotal,
          updatingQuote: false,
          inSyncWithQuote: false,
        };
        // return {
        //   ...state,
        //   ...action.payload,
        // };
      })
      .addCase(getAbandonedData.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          `Quote for the requested quoteId doesn't exist. Please try again with valid quoteId`;
        if (resetCart) {
          return initialState;
        } else {
          state.cartPrepared = 'failed';
          state.prepareCartErrorMessage = action?.error?.message;
        }
      })
      .addCase(getQuote.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(getQuote.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          "Quote for the requested quoteId doesn't exist. Please try again with valid quoteId";
        return resetCart ? initialState : state;
      })
      .addCase(removeItemFromCart.fulfilled, (state, action) => {
        let items = Object.values(action.payload?.items || {});
        let subTotal = cacluclateSubTotal(items);
        return {
          ...state,
          ...action.payload,
          itemsCount: items.length,
          subTotal,
        };
      })
      .addCase(removeItemFromCart.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          "The Cart doesn't contain the item requested to delete.";
        return resetCart ? initialState : state;
      })
      .addCase(updateTax.fulfilled, (state, action) => {
        return {
          ...state,
          quote: {
            ...state.quote,
            ...action.payload,
          },
        };
      })
      .addCase(updateTax.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          "Quote for the requested quoteId doesn't exist. Please try again with valid quoteId";
        return resetCart ? initialState : state;
      })
      .addCase(addCoupon.fulfilled, (state, action) => {
        return {
          ...state,
          quote: {
            ...state.quote,
            ...action.payload,
          },
        };
      })
      .addCase(addCoupon.rejected, (state, action) => {
        return {
          ...state,
          quote: {
            ...state.quote,
            ...action.payload,
            couponCodeMessage: action?.error?.message,
          },
        };
      })
      .addCase(removeCoupon.fulfilled, (state, action) => {
        return {
          ...state,
          quote: {
            ...state.quote,
            ...action.payload,
          },
        };
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        return {
          ...initialState,
          placedOrder: action.payload,
        };
      })
      .addCase(placeOrder.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          `Active Quote for the requested quoteId doesn't exist. Please try again with valid quoteId`;

        return resetCart ? initialState : state;
      })
      .addCase(placeNewOrder.fulfilled, (state, action) => {
        return {
          ...initialState,
          placedOrder: action.payload,
        };
      })
      .addCase(placeNewOrder.rejected, (state, action) => {
        if (action?.payload?.additionalInfo?.quoteAlreadyHasOrder) {
          Router.replace(`/error/quote-already-has-order`);
        } else {
          let resetCart =
            action?.payload?.message ==
            `Active Quote for the requested quoteId doesn't exist. Please try again with valid quoteId`;
          toast.error(action?.payload?.message || 'Order Failed!', {
            theme: 'colored',
            autoClose: false,
          });
          return resetCart ? initialState : state;
        }
      })
      .addCase(prepareCart.pending, (state, action) => {
        state.cartPrepared = null;
      })
      .addCase(prepareCart.fulfilled, (state, action) => {
        state.cartPrepared = action.payload?.sslAvsResponse;
        state.prepareCartErrorMessage = '';
        if (!action.payload?.isCouponValid) {
          toast.error('This coupon code is invalid.', {
            theme: 'colored',
            autoClose: false,
          });
        }
      })
      .addCase(prepareCart.rejected, (state, action) => {
        let resetCart =
          action?.error?.message ==
          `Quote for the requested quoteId doesn't exist. Please try again with valid quoteId`;
        if (resetCart) {
          return initialState;
        } else {
          state.cartPrepared = 'failed';
          state.prepareCartErrorMessage = action?.error?.message;
        }
      })
      .addCase(addFreeGift.fulfilled, (state, action) => {
        return {
          ...state,
          freeProduct: action.payload,
        };
      })
      .addCase(removeFreeGift.fulfilled, (state, action) => {
        return {
          ...state,
          freeProduct: action.payload,
        };
      });
  },
});

export const {
  updateFreeGift,
  setReferralCode,
  resetQuote,
  removeOldOrder,
  resetCart,
  setClearCart,
  resetFreeGift,
} = cartSlice.actions;

export const getCart = (state) => state.cart;
export const getCartItems = (state) => state.cart.items;
export const getSubtotal = (state) => state.cart.subTotal;
export const quoteId = (state) => state.cart.quoteId;
export const getQuoteDetails = (state) => state.cart.quote;
export const noOfItemsInCart = (state) => state.cart.itemsCount;
export const getOrderId = (state) => state.cart.placedOrder?.incrementId;
export const getEmailWarningValue = (state) =>
  state.cart.placedOrder?.emailDeliverable ?? true;
export const getFreegift = (state) => state.cart.freeProduct;
export default cartSlice.reducer;
