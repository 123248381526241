export function formatDate(d) {
  const months = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };
  if (d) {
    var t = d?.split(/[- :]/);
    var splitDate = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    const date = new Date(splitDate);
    let day = date.getDate();
    let mm = date.getMonth() + 1;
    const year = date.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    const month = months[mm];

    return `${month} ${day}, ${year}`;
  }
  return null;
}
export function formatDateForViewOrder(d) {
  if (d) {
    var t = d?.split(/[- :]/);
    var splitDate = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    const date = new Date(splitDate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear().toString().substr(-2);
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return `${month}/${day}/${year}`;
  }
  return null;
}

export function formatDateNew(d) {
  let date = new Date(d);
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
  );
}

export function formatMonthAndDate(d) {
  let date = new Date(d);
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[date.getMonth()] + ' ' + date.getDate();
}

export const routingFunction = (validate) => {
  return Object.keys(validate).length !== 0 ? true : false;
};
export function formatDateForApi(d) {
  const splitDate = d.split(' ');

  const months = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };

  return `${splitDate[3]}-${months[splitDate[1]]}-${splitDate[2]}`;
}

export const stateCodeMatrix = {
  1: 'Alabama',
  2: 'Alaska',
  3: 'American Samoa',
  4: 'Arizona',
  5: 'Arkansas',
  6: 'Armed Forces Africa',
  7: 'Armed Forces Americas',
  8: 'Armed Forces Canada',
  9: 'Armed Forces Europe',
  10: 'Armed Forces Middle East',
  11: 'Armed Forces Pacific',
  12: 'California',
  13: 'Colorado',
  14: 'Connecticut',
  15: 'Delaware',
  16: 'District of Columbia',
  17: 'Federated States Of Micronesia',
  18: 'Florida',
  19: 'Georgia',
  20: 'Guam',
  21: 'Hawaii',
  22: 'Idaho',
  23: 'Illinois',
  24: 'Indiana',
  25: 'Iowa',
  26: 'Kansas',
  27: 'Kentucky',
  28: 'Louisiana',
  29: 'Maine',
  30: 'Marshall Islands',
  31: 'Maryland',
  32: 'Massachusetts',
  33: 'Michigan',
  34: 'Minnesota',
  35: 'Mississippi',
  36: 'Missouri',
  37: 'Montana',
  38: 'Nebraska',
  39: 'Nevada',
  40: 'New Hampshire',
  41: 'New Jersey',
  42: 'New Mexico',
  43: 'New York',
  44: 'North Carolina',
  45: 'North Dakota',
  46: 'Northern Mariana Islands',
  47: 'Ohio',
  48: 'Oklahoma',
  49: 'Oregon',
  50: 'Palau',
  51: 'Pennsylvania',
  52: 'Puerto Rico',
  53: 'Rhode Island',
  54: 'South Carolina',
  55: 'South Dakota',
  56: 'Tennessee',
  57: 'Texas',
  58: 'Utah',
  59: 'Vermont',
  60: 'Virgin Islands',
  61: 'Virginia',
  62: 'Washington',
  63: 'West Virginia',
  64: 'Wisconsin',
  65: 'Wyoming',
};

export const supportedCountryList = ['US', 'CA'];

export const getStateCodeFromStatesList = (code, array) => {
  if (code) {
    return parseInt(array?.find((item) => item?.state_code == code)?.state_id);
  }
};

export const getStateNameFromStatesList = (code, array) => {
  if (code) {
    return array?.find((item) => item?.state_id == code)?.name;
  }
};

export const getStateDetailsFromStatesList = (id, array) => {
  if (id) {
    return array?.find((item) => item?.state_id == id);
  }
};

export const getStateIdFromStatesList = (code, array) => {
  if (code) {
    return array?.find((item) => item?.state_id == code)?.state_code;
  }
};

export const codeToStateMatrix = {
  AL: 1,
  AK: 2,
  AS: 3,
  AZ: 4,
  AR: 5,
  CA: 12,
  CO: 13,
  CT: 14,
  DE: 15,
  DC: 16,
  FM: 17,
  FL: 18,
  GA: 19,
  GU: 20,
  HI: 21,
  ID: 22,
  IL: 23,
  IN: 24,
  IA: 25,
  KS: 26,
  KY: 27,
  LA: 28,
  ME: 29,
  MH: 30,
  MD: 31,
  MA: 32,
  MI: 33,
  MN: 34,
  MS: 35,
  MO: 36,
  MT: 37,
  NE: 38,
  NV: 39,
  NH: 40,
  NJ: 41,
  NM: 42,
  NY: 43,
  NC: 44,
  ND: 45,
  MP: 46,
  OH: 47,
  OK: 48,
  OR: 49,
  PW: 50,
  PA: 51,
  PR: 52,
  RI: 53,
  SC: 54,
  SD: 55,
  TN: 56,
  TX: 57,
  UT: 58,
  VT: 59,
  VI: 60,
  VA: 61,
  WA: 62,
  WV: 63,
  WI: 64,
  WY: 65,
};

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const monthsToIndex = {
  '1 - January': '1',
  '2 - February': '2',
  '3 - March': '3',
  '4 - April': '4',
  '5 - May': '5',
  '6 - June': '6',
  '7 - July': '7',
  '8 - August': '8',
  '9 - September': '9',
  '10 - October': '10',
  '11 - November': '11',
  '12 - December': '12',
};

export const CCToAbbreviation = {
  mastercard: 'MC',
  visa: 'VI',
  amex: 'AE',
  amex: 'AM',
  discover: 'DI',
};

export const abbreviateToCC = {
  MC: 'Mastercard',
  VI: 'Visa',
  AE: 'American Express',
  AM: 'American Express',
  DI: 'Discover',
};

export const intervalConversion = {
  'Every 2 Weeks': 'every2weeks',
  'Every 3 Weeks': 'every3weeks',
  'Every Month': 'every1month',
  'Every 5 Weeks': 'every5weeks',
  'Every 6 Weeks': 'every6weeks',
  'Every 7 Weeks': 'every7weeks',
  'Every 2 Months': 'every2months',
  'Every 3 Months': 'every3months',
  'Every 4 Months': 'every4months',
  'Every 5 Months': 'every5months',
  'Every 6 Months': 'every6months',
};

export const dateOptionsFromInterval = {
  'Every 2 Weeks': { weeks: 2 },
  'Every 3 Weeks': { weeks: 3 },
  'Every Month': { months: 1 },
  'Every 5 Weeks': { weeks: 5 },
  'Every 6 Weeks': { weeks: 6 },
  'Every 7 Weeks': { weeks: 7 },
  'Every 2 Months': { months: 2 },
  'Every 3 Months': { months: 3 },
  'Every 4 Months': { months: 4 },
  'Every 5 Months': { months: 5 },
  'Every 6 Months': { months: 6 },
};

export const serverIntervalConversion = {
  '2 weeks': 'Every 2 Weeks',
  '3 weeks': 'Every 3 Weeks',
  '1 month': 'Every Month',
  '5 weeks': 'Every 5 Weeks',
  '6 weeks': 'Every 6 Weeks',
  '7 weeks': 'Every 7 Weeks',
  '2 months': 'Every 2 Months',
  '3 months': 'Every 3 Months',
  '4 months': 'Every 4 Months',
  '5 months': 'Every 5 Months',
  '6 months': 'Every 6 Months',
};
export const serverPauseIntervalConversion = {
  '2 Months': '2months',
  '3 Months': '3months',
  '6 Months': '6months',
};

export const defaultHeaders = [
  ['Order #', '40%'],
  ['Date', '48%'],
  ['Ship To', '45%'],
  ['Order Total', '40%'],
  ['Status', '35%'],
  ['Action', '35%'],
];

export const orderHeaders = [
  ['PRODUCT NAME', 1.5],
  ['SKU', 1],
  ['PRICE', 0.5],
  ['QTY', 1, 'right'],
  ['SUBTOTAL', 1, 'right'],
];
export const intervals = [
  { title: 'Every 2 Weeks', dateFormat: { value: 2, type: 'week' } },
  { title: 'Every 3 Weeks', dateFormat: { value: 3, type: 'week' } },
  { title: 'Every Month', dateFormat: { value: 1, type: 'month' } },
  { title: 'Every 5 Weeks', dateFormat: { value: 5, type: 'week' } },
  { title: 'Every 6 Weeks', dateFormat: { value: 6, type: 'week' } },
  { title: 'Every 7 Weeks', dateFormat: { value: 7, type: 'week' } },
  { title: 'Every 2 Months', dateFormat: { value: 2, type: 'month' } },
  { title: 'Every 3 Months', dateFormat: { value: 3, type: 'month' } },
  { title: 'Every 4 Months', dateFormat: { value: 4, type: 'month' } },
  { title: 'Every 5 Months', dateFormat: { value: 5, type: 'month' } },
  { title: 'Every 6 Months', dateFormat: { value: 6, type: 'month' } },
];

export const pauseIntervals = [
  { title: '2 Months', value: '2months' },
  { title: '3 Months', value: '3months' },
  { title: '6 Months', value: '6months' },
];

export const orderQuantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const generateFilteredList = (list) => {
  const filtered = [];

  list[0].result.forEach((order) => {
    filtered.push([
      [order.order_id, 1],
      [formatDate(order.created_at), 1.5],
      [order.billing_name, 1.5],
      [`$${order.grand_total}`, 1],
      [capitalize(order.status), 1],
      ['View Order', 1],
    ]);
  });

  return filtered;
};

export const generateFilteredItems = (items) => {
  const filtered = [];

  items.forEach((item) => {
    const ordered = +item.qty_ordered;
    const returend = +item.qty_refunded;

    filtered.push([
      [item.name, 1.5],
      [item.sku, 1],
      [`$${item.price}`, 1],
      [
        `Ordered: ${ordered.toFixed(0)} Returned: ${returend.toFixed(0)}`,
        1,
        'right',
      ],
      [`$${item.row_total}`, 0.5, 'right'],
    ]);
  });

  return filtered;
};

export const dateIsLessThan6Months = (date) => {
  const today = new Date();
  return date.getMonth() - today.getMonth() <= 6;
};

export const isFreeGift = (sku = '') => {
  return sku.includes('FREE_VIT');
};

function getPositionAtCenter(element) {
  const { top, left, width, height } = element?.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

export const getDistanceBetweenElements = (a, b) => {
  const aPosition = getPositionAtCenter(a);
  const bPosition = getPositionAtCenter(b);

  return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
};

//These functions can be used in applications where date and time information needs to be displayed in a user-friendly format, such as in logs, event schedules, or time-stamped data displays.
// This function converts a Unix timestamp into a human-readable date string formatted as "MMM DD, YYYY" (e.g., "Jul 01, 2023").
// export const unixToDate = (unixdata) => {
//   const date = moment
//     .unix(unixdata)
//     .tz('America/Los_Angeles')
//     .format('MMM DD, YYYY');
//   return date;
// };

// This function converts a Unix timestamp into a human-readable time string formatted as "HH:mm
// " (e.g., "14:30:00").
// export const unixToTime = (unixdata) => {
//   const time = moment
//     .unix(unixdata)
//     .tz('America/Los_Angeles')
//     .format('HH:mm:ss');
//   return time;
// };
